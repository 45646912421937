<template>
  <div>
  </div>
</template>

<script>
export default {
  name: "HotelPublic",
  metaInfo: {
    title: "Hotel",
    titleTemplate: "%s | Aigent",
  },
  created() {
    // window.open(`https://aigentcorporation.com/hotel/${this.$route.params.propertyID}`,"_self");
    // setTimeout(() => {
    //   window.location.replace("https://aigentcorporation.com");
    // }, 2000)
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      //   window.location.replace("https://apps.apple.com/th/app/aigent/id6443768318");
      // } else if(/Android/i.test(navigator.userAgent)) {
      //   window.location.replace("https://play.google.com/store/apps/details?id=com.aigent.app");
      // } else {
      //   window.location.replace(`https://aigentcorporation.com/hotel/${this.$route.params.propertyID}`);
      // }
      // window.location.replace(`https://aigentcorporation.com/hotel/${this.$route.params.propertyID}`);
      window.location.replace(`aigentcorporation://hotel/${this.$route.params.propertyID}`);
    } else {
      window.location.replace("https://aigentcorporation.com");
    }
  },
}
</script>

<style>

</style>